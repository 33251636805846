<template>
  <div class="">
    <el-dialog
      :visible.sync="dialogVisible"
      width="580px"
      :show-close="false"
      :modal-append-to-body="false"
      :lock-scroll="false"
    >
      <div class="details">
        <span class="closeicon" @click="closebox()">
          <i class="el-icon-error"></i>
        </span>
        <div class="wb-tit font18">举报</div>
        <div class="frombox">
          <el-form
            :model="jubaoForm"
            ref="jubaoForm"
            size="small"
            label-width="130px"
            class="demo-ruleForm"
          >
            <el-form-item
              label="举报类型："
              prop="type"
              :rules="[{ required: true, message: '请选择举报类型' }]"
            >
              <el-select
                v-model="jubaoForm.type"
                placeholder="请选择举报类型"
                style="width: 320px"
              >
                <el-option
                  v-for="item in gzoptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="举报内容："
              prop="content"
              :rules="[{ required: true, message: '请输入举报内容' }]"
            >
              <el-input
                type="textarea"
                :autosize="{ minRows: 8, maxRows: 10 }"
                show-word-limit
                placeholder="请输入举报内容"
                v-model="jubaoForm.content"
                style="width: 320px"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="图片描述:"
              prop="imgurl"
              :rules="[{ required: true, message: '请上传图片描述' }]"
            >
              <el-upload
                action="https://freemen.work/user/file/upload/"
                list-type="picture-card"
                :on-preview="handlePictureCardPreview"
                :on-remove="handleRemove"
                :on-success="handleSuccess"
              >
                <i class="el-icon-plus"></i>
              </el-upload>
              <el-dialog :visible.sync="updialogVisible"
                ><img
                  width="100%"
                  :src="'https://freemen.work/user/file/view/' + imgShow"
                  alt=""
              /></el-dialog>
            </el-form-item>
            <el-form-item>
              <el-button class="qux" @click="closebox()">取消</el-button>
              <el-button
                type="primary"
                class="btn-next"
                @click="submitForm('jubaoForm')"
                >确定</el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Bus from "../../common/bus.js";
export default {
  data() {
    return {
      dialogVisible: false,
      jubaoForm: {
        type: "",
        content: "",
        imgurl: "",
      },
      gzoptions: [],
      updialogVisible: false,
      config: {},
      imgShow: "",
    };
  },
  activated() {
    // this.getTypeOption();
  },
  mounted() {
    this.getTypeOption();
    Bus.$on("reportdstuts", (data) => {
      this.dialogVisible = data.show;
      this.config = data.data;
    });
  },
  methods: {
    getTypeOption() {
      this.$api.getdict("get", { code: "company_report" }).then((res) => {
        let arr = [];
        res.data.map((item) => {
          let obj = {
            value: item.id,
            label: item.name,
          };
          arr.push(obj);
        });
        this.gzoptions = arr;
      });
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let config = {
            type: 2,
            warnType: this.jubaoForm.type,
            userId: this.config.userId,
            userJobId: this.config.userJobId,
            warnContent: this.jubaoForm.content,
            warnImage: this.jubaoForm.imgurl,
          };
          this.$api.warn("post", config).then(() => {
            this.$message({
              message: "提交成功",
              type: "success",
            });
            this.closebox();
          });
        } else {
          return false;
        }
      });
    },
    closebox() {
      this.dialogVisible = false;
      Bus.$emit("reportdstuts", false);
      // Bus.$emit('searchresumedstuts', true)
    },
    handleRemove(file, fileList) {
      // console.log(file, fileList);
      let arr = [];
      fileList.map((item) => {
        arr.push(item.response.data);
      });
      this.jubaoForm.imgurl = arr.join(",");
    },
    handlePictureCardPreview(file) {
      this.updialogVisible = true;
      this.imgShow = file.response.data;
    },
    handleSuccess(response, file, fileList) {
      // console.log(file, fileList);
      let arr = [];
      fileList.map((item) => {
        arr.push(item.response.data);
      });
      this.jubaoForm.imgurl = arr.join(",");
    },
  },
};
</script>
<style type="text/css">
.el-dialog__header,
.el-dialog__body {
  padding: 0 !important;
}
</style>
<style lang="less" scoped>
.closeicon {
  position: absolute;
  top: -40px;
  right: 0;
  font-size: 26px;
  color: #f3f3f3;
}
.details {
  .wb-tit {
    padding: 17px 0;
    text-align: center;
    background-color: #f3f3f3;
    color: #000000;
    font-weight: 600;
  }
  .frombox {
    padding: 50px 80px 50px 50px;
    /deep/ .el-input__inner,
    /deep/ .el-textarea__inner {
      border-radius: 0px;
    }
    /deep/ .el-button {
      border-radius: 0px;
    }
    /deep/ .el-upload--picture-card {
      width: 80px;
      height: 80px;
      border-radius: 0px;
    }
    /deep/ .el-upload {
      width: 80px;
      height: 80px;
      line-height: 80px;
      border-radius: 0px;
    }
    /deep/ .el-upload-list--picture-card .el-upload-list__item {
      width: 80px;
      height: 80px;
      line-height: 80px;
      border-radius: 0px;
    }
    /deep/ .el-upload-list--picture-card .el-upload-list__item-thumbnail {
      width: 80px;
      height: 80px;
      line-height: 80px;
      border-radius: 0px;
    }
    /deep/ .avatar {
      width: 80px;
      height: 80px;
      border-radius: 0px;
    }
    .demo-ruleForm {
      button {
        width: 155px;
        height: 40px;
      }
      .qux {
        background-color: #fff;
        border: 1px solid #00bcff;
        color: #00bcff;
      }
      .btn-next {
        background-color: #00bcff;
        border: none;
      }
    }
  }
}
</style>
