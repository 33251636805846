<template>
  <div class="">
    <el-dialog
      :visible.sync="dialogVisible"
      width="580px"
      :show-close="false"
      :modal-append-to-body="false"
      :lock-scroll="false"
    >
      <div class="details">
        <span class="closeicon" @click="closebox()"
          ><i class="el-icon-error"></i
        ></span>
        <div class="wb-tit font18">
          简历详情页
          <div class="caozuo font22">
            <div class="jubao" @click="jubao()">
              <i class="el-icon-warning-outline"></i>
            </div>
            <div class="collect" @click="scstuts = !scstuts">
              <i :class="scstuts ? 'el-icon-star-on' : 'el-icon-star-off'"></i>
              <!-- <i class=""></i> -->
            </div>
          </div>
        </div>
        <div class="infobox">
          <div class="person-info">
            <div class="p-picurl">
              <img src="../../assets/images/position/edit.png" />
              <span class="bofang font20" @click="videoshow()">
                <!-- <i class="el-icon-video-play"></i> -->
                <img src="../../assets/images/common/bofangicon.png" />
              </span>
            </div>
            <div class="p-news">
              <div class="pn-name">
                <div class="namemain">
                  <span class="font22">胡歌</span>
                  <img src="../../assets/images/person/boy.png" />
                </div>
                <div class="score font14"><span>4.5</span></div>
              </div>
              <div class="beizhu font14">5年 | 本科 | 34岁 | 8-10k</div>
            </div>
          </div>
          <div class="worktime">
            <div class="erji-tit font22">期望工作时间</div>
            <div class="time-main">
              <img src="../../assets/images/person/timeqi.png" />
              <div class="">2020/08</div>
              <span>-</span>
              <img src="../../assets/images/person/timezhi.png" />
              <div class="">2021/05</div>
            </div>
          </div>
          <div class="intention">
            <div class="erji-tit font22">求职意向</div>
            <div class="workinfo">
              <div class="font18">安卓开发</div>
              <span>行业不限 | 10-15k</span>
            </div>
            <div class="workbiaoqian font14">
              <span>spring</span>
              <span>springBoot</span>
              <span>spring</span>
              <span>springBoot</span>
            </div>
            <div class="workexperience">
              <div class="erji-tit font22">工作经历</div>
              <div
                class="experiencelist"
                v-for="(item, index2) in 2"
                :key="index2"
              >
                <div class="gs-info">
                  <div class="gi-left font18">
                    杭州最田源科技有限公司
                    <span class="font14">java开发</span>
                  </div>
                  <div class="gi-right font14">2019.05-至今</div>
                </div>
                <div class="workbiaoqian font14">
                  <span>spring</span>
                  <span>springBoot</span>
                  <span>spring</span>
                  <span>springBoot</span>
                </div>
                <div class="messageall font16">
                  <div class="">负责安卓app的开发，日常修复，版本迭代</div>
                  <div class="">
                    接手上一个开发的项目，熟悉了两天代码后一个人负责项目的开发
                  </div>
                  <div class="">
                    项目中使用retrofit,rxjava,okhttp3搭建网络框架，app是一款提供保险顾问和有购买保险意向客户交流的平台
                  </div>
                  <div class="">
                    有顾问在线直播讲解保险知识。客户可以在线咨询顾问来解决疑问。也可以在平台发布问题，等待顾问解答
                  </div>
                </div>
              </div>
            </div>
            <div class="workexperience">
              <div class="erji-tit font22">项目经历</div>
              <div
                class="experiencelist"
                v-for="(item, index2) in 2"
                :key="index2"
              >
                <div class="gs-info">
                  <div class="gi-left font18">Freeman | 安卓开发</div>
                  <div class="gi-right font14">2019.05-至今</div>
                </div>
                <div class="workbiaoqian font14">
                  <span>spring</span>
                  <span>springBoot</span>
                  <span>spring</span>
                  <span>springBoot</span>
                </div>
                <div class="messageall font16">
                  <div class="">负责安卓app的开发，日常修复，版本迭代</div>
                  <div class="">
                    接手上一个开发的项目，熟悉了两天代码后一个人负责项目的开发
                  </div>
                  <div class="">
                    项目中使用retrofit,rxjava,okhttp3搭建网络框架，app是一款提供保险顾问和有购买保险意向客户交流的平台
                  </div>
                  <div class="">
                    有顾问在线直播讲解保险知识。客户可以在线咨询顾问来解决疑问。也可以在平台发布问题，等待顾问解答
                  </div>
                </div>
              </div>
            </div>
            <div class="education">
              <div class="erji-tit font22">教育经历</div>
              <div class="education-main">
                <div class="em-left font18">
                  浙江工商大学 | 计算机科学与技术 | 本科
                </div>
                <div class="em-right font14">2013-2017</div>
              </div>
            </div>
            <div class="education">
              <div class="erji-tit font22">资格证书</div>
              <div class="education-main">
                <div class="em-left font18">大学英语六级证书</div>
              </div>
            </div>
            <div class="education">
              <div class="erji-tit font22">外语能力</div>
              <div class="education-main">
                <div class="em-left font18">英语 | CET-6</div>
              </div>
            </div>
            <div class="btn-caozuo">
              <el-button type="primary" class="qx">取消</el-button>
              <el-button type="primary" class="yy er">面试邀约</el-button>
              <el-button type="primary" class="yy">沟通</el-button>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Bus from "../../common/bus.js";
export default {
  data() {
    return {
      dialogVisible: false,
      scstuts: false,
    };
  },
  mounted() {
    Bus.$on("resumedstuts", (data) => {
      // console.log("接受到", data);
      this.dialogVisible = data;
    });
  },
  methods: {
    closebox() {
      this.dialogVisible = false;
      Bus.$emit("resumedstuts", false);
    },
    // 视频详情
    videoshow() {
      // Bus.$emit('resumevideodstuts', true);
    },
    // 举报
    jubao() {
      this.closebox();
      Bus.$emit("reportdstuts", true);
    },
  },
};
</script>
<style type="text/css">
.el-dialog__header,
.el-dialog__body {
  padding: 0 !important;
}
</style>
<style lang="less" scoped>
.closeicon {
  position: absolute;
  top: -40px;
  right: 0;
  font-size: 26px;
  color: #f3f3f3;
}
.details {
  .wb-tit {
    padding: 17px 0;
    text-align: center;
    background-color: #f3f3f3;
    color: #000000;
    font-weight: 600;
    position: relative;
    .caozuo {
      position: absolute;
      top: 17px;
      right: 30px;
      display: flex;
      align-items: center;
      .jubao:hover {
        cursor: pointer;
      }
      .jubao {
      }
      .collect:hover {
        cursor: pointer;
      }
      .collect {
        margin-left: 14px;
        width: 20px;
        .el-icon-star-off,
        .el-icon-star-on {
          color: #ffa800;
        }
        .el-icon-star-on {
          font-size: 26px !important;
        }
      }
    }
  }
}

.infobox {
  padding: 40px 30px;
  .person-info {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #f3f3f3;
    padding-bottom: 20px;
    .p-picurl {
      margin-right: 20px;
      position: relative;
      img {
        width: 50px;
        height: 50px;
      }
      .bofang:hover {
        cursor: pointer;
      }
      .bofang {
        position: absolute;
        top: -3px;
        right: -6px;
        // border-radius: 50px;
        // color: #3F3F3F;
        img {
          width: 22px;
          height: 22px;
        }
      }
    }
    .p-news {
      display: flex;
      flex: 1;
      flex-direction: column;
      line-height: 1.6;
      .pn-name {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .namemain {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          color: #111111;
          img {
            width: 24px;
            height: 24px;
            margin: 0 18px 0 10px;
          }
          span:last-child {
            background-color: #e9f9ff;
            color: #00bcff;
            padding: 2px 10px;
          }
        }
        .score {
          background-color: #00bcff;
          border-radius: 8px 8px 8px 0;
          color: #ffffff;
          width: 30px;
          height: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      .beizhu {
        color: #666666;
      }
    }
  }
  .erji-tit {
    margin: 20px 0 20px 0;
    color: #111111;
  }
  .worktime {
    .time-main {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding-bottom: 20px;
      border-bottom: 1px solid #f3f3f3;
      img {
        width: 50px;
        height: 50px;
        margin-right: 5px;
      }
      span {
        margin: 0 30px;
      }
    }
  }
  .intention {
    .workinfo {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      color: #111111;
      font-weight: 600;
      span {
        color: #666666;
        margin-left: 10px;
        font-weight: 500;
      }
    }
    .workbiaoqian {
      padding: 20px 0;
      border-bottom: 1px solid #f3f3f3;
      display: flex;
      flex-wrap: wrap;
      span {
        background-color: #eeeeee;
        padding: 4px 8px;
        margin: 0 10px 10px 0;
        color: #666666;
      }
    }
    .workexperience {
      .experiencelist {
        border-bottom: 1px solid #f3f3f3;
        padding-bottom: 15px;
        margin-top: 30px;
        .gs-info {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .gi-left {
            color: #111111;
            span {
              color: #666666;
              margin-left: 15px;
            }
          }
          .gi-right {
            color: #111111;
          }
        }
        .workbiaoqian {
          padding: 20px 0;
          border-bottom: 1px solid #f3f3f3;
          display: flex;
          flex-wrap: wrap;
          span {
            background-color: #eeeeee;
            padding: 4px 8px;
            margin: 0 10px 10px 0;
            color: #666666;
          }
        }
        .messageall {
          margin-top: 20px;
          color: #333333;
          line-height: 2;
        }
      }
    }
    .education {
      border-bottom: 1px solid #f3f3f3;
      padding-bottom: 20px;
      .education-main {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #111111;
        .em-left {
          color: #333;
        }
        .em-right {
        }
      }
    }
    .btn-caozuo {
      margin-top: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      /deep/ .el-button {
        border-radius: 0px;
        background-color: #00bcff;
        border: none;
      }
      button {
        width: 150px;
      }
      .qx {
        border: 1px solid #00bcff;
        background-color: #ffffff;
        color: #00bcff;
      }
      .yy {
      }
    }
  }
}
</style>
