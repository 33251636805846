<template>
  <el-container style="height: 100%; overflow: hidden">
    <el-aside width="300px">
      <div class="h-left font18">
        <img src="../assets/logo.png" alt />
        <span v-if="!isCollapse">企业服务版</span>
      </div>
      <div class="" style="overflow: hidden">
        <el-menu
          v-if="menulist.length"
          router
          background-color="#2A6378"
          text-color="#A6A6A6"
          active-text-color="#FFFFFF"
          unique-opened
          :default-active="$route.path"
          style="width: 300px"
          @open="handleOpen"
        >
          <el-submenu
            :index="item.id + ''"
            v-for="item in menulist"
            :key="item.id"
            :class="item.path"
          >
            <template slot="title">
              <i :class="item.icon"></i>
              <span>{{ item.authName }}</span>
            </template>
            <el-menu-item
              :index="'/' + subItem.path"
              v-for="subItem in item.children"
              :key="subItem.id"
            >
              <template slot="title">
                <span style="margin-left: 58px">{{ subItem.authName }}</span>
              </template>
            </el-menu-item>
          </el-submenu>
        </el-menu>
      </div>
    </el-aside>
    <el-container>
      <el-header style="height: 70px; padding: 0">
        <div class="h-right">
          <div class="btn">
            <!-- <div class="toggle-button" @click="toggleCollapse"><i :class="isCollapse?'el-icon-s-unfold':'el-icon-s-fold'"></i></div> -->
            <div class="navigation font20">{{ $route.name }}</div>
          </div>
          <div class="user_info">
            <div
              class="tab"
              @click="$router.push('message')"
              v-if="
                (userInfo.isAdmin == 1 && userInfo.status == 1) ||
                userInfo.isAdmin != 1
              "
            >
              <el-badge :value="talk ? talk : ''" class="item" type="danger"
                ><span>系统消息</span></el-badge
              >
            </div>
            <div
              class="tab"
              @click="goChat()"
              v-if="
                (userInfo.isAdmin == 1 && userInfo.status == 1) ||
                userInfo.isAdmin != 1
              "
            >
              <!-- <el-badge class="item" type="danger"><span>消息</span></el-badge> -->
              <el-badge v-if="newMsgNum > 0" :value="newMsgNum" class="item">
                {{ "消息" }}
              </el-badge>
              <span v-else>{{ "消息" }}</span>
            </div>
            <div
              class="tab"
              @click="$router.push('interviewP')"
              v-if="
                (userInfo.isAdmin == 1 && userInfo.status == 1) ||
                userInfo.isAdmin != 1
              "
            >
              <el-badge
                :value="userMessage.interview ? userMessage.interview : ''"
                class="item"
                type="danger"
                ><span>面试</span></el-badge
              >
            </div>
            <div
              class="tab"
              @click="$router.push('toudiP')"
              v-if="
                (userInfo.isAdmin == 1 && userInfo.status == 1) ||
                userInfo.isAdmin != 1
              "
            >
              <el-badge
                :value="userMessage.postCv ? userMessage.postCv : ''"
                class="item"
                type="danger"
                ><span>投递</span></el-badge
              >
            </div>
            <div
              class="tab"
              @click="$router.push('help')"
              v-if="
                (userInfo.isAdmin == 1 && userInfo.status == 1) ||
                userInfo.isAdmin != 1
              "
            >
              帮助反馈
            </div>
            <!-- 'https://freemen.work/user/file/view/' + userInfo.avatar -->
            <el-avatar>
              <img :src="
                userInfo.avatar
                  ? baseUrl + '/company/file/view/' + userInfo.avatar
                  : 'https://freemen.work/user/file/view/files/20220311/ic_launcher_gray_1646968576594.png'
              "  style="object-fit: cover" >
            </el-avatar>
            <el-dropdown style="margin-left: 10px" @command="handleCommand">
              <span class="el-dropdown-link">
                {{ userInfo.displayName }}
                <i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="geren">个人资料</el-dropdown-item>
                <el-dropdown-item v-if="userInfo.isAdmin" command="guanlian">账号关联</el-dropdown-item>
                <el-dropdown-item command="logout">退出登录</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
      </el-header>
      <el-main style="padding: 20px 126px 20px 126px">
        <keep-alive
          ><router-view v-if="$route.meta.keepAlive"></router-view
        ></keep-alive>
        <router-view v-if="!$route.meta.keepAlive"></router-view>
        <div class="f-tab font14">
          <div class="one">
            <a href="https://freemen.work/PrivacyPolicy.html" target="__blank"
              >隐私政策
            </a>
            <!-- target="__blank" -->
            <a href="/#/help">投诉反馈 </a>
            <a href="/#/commonwt">常见问题 </a>
            <a href="/#/aboutus?id=610">联系我们 </a>
          </div>
          <el-divider></el-divider>
          <div class="two">
            © Freemen.work 版权所有 ICP证：浙ICP备20000153号-2
          </div>
          <div class="three">浙ICP备20000153号-2</div>
        </div>

        <!-- 简历弹窗 -->
        <resumedetails></resumedetails>
        <!-- 视频详情 -->
        <!-- <videodetail></videodetail> -->
        <!-- 举报 -->
        <reportPopup></reportPopup>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
// import videodetail from "/src/components/person/videodetail.vue";//视频详情
import reportPopup from "/src/components/person/reportPopup.vue"; //举报
import resumedetails from "/src/components/person/resumedetails.vue";
import axios from "axios"; //简历详情
export default {
  components: {
    resumedetails,
    // videodetail,
    reportPopup,
  },
  data() {
    return {
      userInfo: {},
      menulist: [],
      isCollapse: false,
      sheight: "",
      account: "我的工作台",
      activeIndex: "/home",
      userMessage: {},
      talk: null,
      newMsgNum: 0,
      baseUrl:"",
    };
  },
  created() {
    // this.getMenuList();
    this.getHandData();
    this.sheight = document.documentElement.scrollHeight;
    // console.log("h获取到的高度", this.sheight);
    if(this.baseUrl === ''){
      this.baseUrl = axios.defaults.baseURL ? axios.defaults.baseURL : "https://freemen.work";
    }
    this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    // console.log("HomeNew created ----- getUserInfo by sessionStorage.getItem， userInfo = ", this.userInfo);
    if (this.userInfo.isAdmin == 1) {
      if (this.userInfo.status == 1) {
        if (this.userInfo.companyIdStatus == 1) {
          this.menulist = [
            {
              id: 1,
              authName: "职位管理",
              icon: "el-icon-s-custom",
              children: [
                { id: 2, authName: "入职管理", path: "induction" },
                { id: 3, authName: "入驻管理", path: "recruitmentlist" },
              ],
            },
            {
              id: 4,
              authName: "搜索牛人",
              icon: "el-icon-search",
              path: "searchpage",
            },
            {
              id: 5,
              authName: "牛人管理",
              icon: "el-icon-user-solid",
              children: [
                { id: 6, authName: "已握手牛人", path: "handshake" },
                { id: 7, authName: "在职牛人", path: "onjob" },
                { id: 8, authName: "面试牛人", path: "interviewP" },
                { id: 9, authName: "offer牛人", path: "offerP" },
                { id: 10, authName: "投递牛人", path: "toudiP" },
                { id: 11, authName: "收藏牛人", path: "collectP" },
                { id: 12, authName: "人才库", path: "trash" },
              ],
            },
            {
              id: 13,
              authName: "沟通",
              icon: "el-icon-chat-dot-square",
              path: "chatmain",
            },
            {
              id: 14,
              authName: "道具管理",
              icon: "el-icon-bank-card",
              children: [
                { id: 15, authName: "道具商城", path: "propsmall" },
                { id: 16, authName: "购买记录", path: "buyrecord" },
              ],
            },
            {
              id: 17,
              authName: "企业基础信息",
              icon: "el-icon-tickets",
              children: [
                { id: 18, authName: "企业基本信息", path: "basicinfo" },
                { id: 19, authName: "公司相册", path: "photoalbum" },
                { id: 20, authName: "考勤设置", path: "worktime" },
                { id: 21, authName: "职位等级设置", path: "positionlevel" },
                // {id:22,authName:'企业认证信息',path:'attestation'},
              ],
            },
            {
              id: 23,
              authName: "账号管理",
              icon: "el-icon-user-solid",
              children: [
                { id: 24, authName: "个人资料", path: "personaldata" },
                { id: 25, authName: "账号关联", path: "accountnumber" },
              ],
            },
            {
              id: 26,
              authName: "设置",
              icon: "el-icon-setting",
              children: [
                { id: 27, authName: "常用语设置", path: "commonwords" },
                { id: 28, authName: "修改手机号", path: "changephone" },
                { id: 29, authName: "修改密码", path: "changepassword" },
                { id: 30, authName: "反馈与帮助", path: "help" },
              ],
            },
          ];
        } else {
          this.menulist = [
            {
              id: 1,
              authName: "职位管理",
              icon: "el-icon-s-custom",
              children: [
                { id: 2, authName: "入职管理", path: "induction" },
                { id: 3, authName: "入驻管理", path: "recruitmentlist" },
              ],
            },
            {
              id: 4,
              authName: "搜索牛人",
              icon: "el-icon-search",
              path: "searchpage",
            },
            {
              id: 5,
              authName: "牛人管理",
              icon: "el-icon-user-solid",
              children: [
                { id: 6, authName: "已握手牛人", path: "handshake" },
                { id: 7, authName: "在职牛人", path: "onjob" },
                { id: 8, authName: "面试牛人", path: "interviewP" },
                { id: 9, authName: "offer牛人", path: "offerP" },
                { id: 10, authName: "投递牛人", path: "toudiP" },
                { id: 11, authName: "收藏牛人", path: "collectP" },
                { id: 12, authName: "人才库", path: "trash" },
              ],
            },
            {
              id: 13,
              authName: "沟通",
              icon: "el-icon-chat-dot-square",
              path: "chatmain",
            },
            {
              id: 14,
              authName: "道具管理",
              icon: "el-icon-bank-card",
              children: [
                { id: 15, authName: "道具商城", path: "propsmall" },
                { id: 16, authName: "购买记录", path: "buyrecord" },
              ],
            },
            {
              id: 17,
              authName: "企业基础信息",
              icon: "el-icon-tickets",
              children: [
                { id: 18, authName: "企业基本信息", path: "basicinfo" },
                { id: 19, authName: "公司相册", path: "photoalbum" },
                { id: 20, authName: "考勤设置", path: "worktime" },
                { id: 21, authName: "职位等级设置", path: "positionlevel" },
                // { id: 22, authName: "企业认证信息", path: "attestation" },
              ],
            },
            {
              id: 23,
              authName: "账号管理",
              icon: "el-icon-user-solid",
              children: [
                { id: 24, authName: "个人资料", path: "personaldata" },
                { id: 25, authName: "账号关联", path: "accountnumber" },
              ],
            },
            {
              id: 26,
              authName: "设置",
              icon: "el-icon-setting",
              children: [
                { id: 27, authName: "常用语设置", path: "commonwords" },
                { id: 28, authName: "修改手机号", path: "changephone" },
                { id: 29, authName: "修改密码", path: "changepassword" },
                { id: 30, authName: "反馈与帮助", path: "help" },
              ],
            },
          ];
        }
      } else {
        this.menulist = [];
      }
    } else {
      this.menulist = [
        {
          id: 1,
          authName: "职位管理",
          icon: "el-icon-s-custom",
          children: [
            { id: 2, authName: "入职管理", path: "induction" },
            { id: 3, authName: "入驻管理", path: "recruitmentlist" },
          ],
        },
        {
          id: 4,
          authName: "搜索牛人",
          icon: "el-icon-search",
          path: "searchpage",
        },
        {
          id: 5,
          authName: "牛人管理",
          icon: "el-icon-user-solid",
          children: [
            { id: 6, authName: "已握手牛人", path: "handshake" },
            { id: 7, authName: "在职牛人", path: "onjob" },
            { id: 8, authName: "面试牛人", path: "interviewP" },
            { id: 9, authName: "offer牛人", path: "offerP" },
            { id: 10, authName: "投递牛人", path: "toudiP" },
            { id: 11, authName: "收藏牛人", path: "collectP" },
            { id: 12, authName: "人才库", path: "trash" },
          ],
        },
        {
          id: 13,
          authName: "沟通",
          icon: "el-icon-chat-dot-square",
          path: "chatmain",
        },
        {
          id: 14,
          authName: "道具管理",
          icon: "el-icon-bank-card",
          children: [
            { id: 15, authName: "道具商城", path: "propsmall" },
            { id: 16, authName: "购买记录", path: "buyrecord" },
          ],
        },
        {
          id: 26,
          authName: "设置",
          icon: "el-icon-setting",
          children: [
            { id: 27, authName: "常用语设置", path: "commonwords" },
            { id: 28, authName: "修改手机号", path: "changephone" },
            { id: 29, authName: "修改密码", path: "changepassword" },
            { id: 30, authName: "反馈与帮助", path: "help" },
          ],
        },
      ];
    }

    // 添加消息监听，接收消息时更新未读消息num
    // this.$IM.removeEventHandlerAll('onReceivedMessage');
    // if (window.IMlogin) {
    //   this.getNewMsgNum(false);
    //   this.$IM.onReceivedMessage((message) => {
    //     console.log("IM.onReceivedMessage on top 数据接收成功 message----", message);
    //     this.getNewMsgNum(false);
    //   })
    // } else {
    //   window.noIMlogin = true;
    //   setTimeout(() => {
    //     this.$IM.onReceivedMessage((message) => {
    //       console.log("IM.onReceivedMessage on top 数据接收成功 message----", message);
    //       this.getNewMsgNum(false);
    //     })
    //   }, 1500);
    // }
  },
  mounted() {
    document.body.parentNode.style.overflowY = "hidden";
  },
  destroyed() {
    // console.log("Home new destroyed--------------------");
    // this.$IM.removeEventHandlerAll('all');
  },
  // watch: {
  //   $router(value) {
  //     console.log("Home menu top, watch router, v = ", value);
  //   },
  // },
  methods: {
    goChat(){
      this.getNewMsgNum(true);
      this.$router.push({ path: 'chatmain', query: { active: 1 } })
    },
    getNewMsgNum(toSetRead) {
      let newNum = 0;
      this.$IM.getList().then((res) => {
        // console.log("top menu, to update the new message unread_num, result=", res);
        let channelList = res.data.channel_infos;
        if(channelList.length > 0){
          channelList.map((v)=>{
            if(v.unread_num > 0){
              if(toSetRead){
                this.$IM.read({
                  id: JSON.parse(v.meta.payload).from,
                })
              } else {
                newNum = newNum + v.unread_num;
              }
            }
          })
        }
        this.newMsgNum = newNum;
      });
    },
    getHandData() {
      //获取数据
      this.$api.getHandData("get").then((res) => {
        this.userMessage = res.data;
      });
      this.$api.getNoreadList("get").then((res) => {
        this.talk = res.data;
      });
    },
    handleCommand(command) {
      switch (command) {
        case "geren":
          this.$router.push("/personaldata");
          break;
        case "guanlian":
          this.$router.push("/accountnumber");
          break;
        case "logout":
          this.loginout();
          break;
      }
    },
    loginout() {
      this.$api.outlogin("post").then(() => {
        window.sessionStorage.clear;

        this.$router.push("/login");
        this.$IM.outLogin();
      });
    },
    async getMenuList() {
      const { data } = await this.$http.get("menus");
      if (data.meta.status === 200) {
        // console.log(data.data);
        // console.log("权限列表", this.$qx);
        this.menulist = this.$qx;
      } else {
        // console.log("权限列表", this.$qx);
        this.menulist = this.$qx;
        this.$message({
          type: "error",
          message: data.meta.msg,
        });
      }
    },
    toggleCollapse() {
      this.isCollapse = !this.isCollapse;
    },
    handleOpen(key, keyPath) {
      // console.log(key, keyPath);
      if (keyPath == 13) {
        if (this.$route.path != "/chatmain") {
          this.$router.push("/chatmain");
        }
      }
      if (keyPath == 4) {
        if (this.$route.path != "/searchpage") {
          this.$router.push("/searchpage");
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
// 左侧导航栏
.el-aside {
  background-color: #2a6378;
  color: #333;
  text-align: center;
  line-height: 200px;
}
.el-aside {
  // height: 370px;
  overflow: auto;
}
.el-aside::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 0px;
  height: 1px;
}
.el-aside::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 4px;
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: #a0a0a0;
}

/deep/.el-submenu__title {
  color: #ffffff !important;
  font-size: 15px;
  text-align: left;
  padding-left: 70px !important;
}
/deep/.el-submenu__title i {
  color: #ffffff !important;
}
/deep/.el-submenu__icon-arrow {
  right: 50px;
  font-size: 16px;
}
/deep/.el-submenu,
/deep/.el-menu-item {
  text-align: left;
  padding-left: 0;
}

.chatmain {
  /deep/.el-submenu__icon-arrow,
  /deep/.el-icon-arrow-down {
    display: none;
  }
}
.searchpage {
  /deep/.el-submenu__icon-arrow,
  /deep/.el-icon-arrow-down {
    display: none;
  }
}

.el-main {
  background-color: #f6f8f9;
  // line-height: 160px;
}

body > .el-container {
  margin-bottom: 40px;
}

// 主要
.h-left {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #1d4a5b;
  height: 70px;
  width: 300px;
  img {
    width: 45px;
    height: 45px;
  }
  span {
    margin-left: 15px;
    color: #ffffff;
    font-size: 20px;
  }
}
// 上方header
.h-right {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff;
  height: 100%;
  padding: 0 127px;
  .btn {
    .navigation {
      color: #111111;
      font-weight: 600;
    }
  }
  .user_info {
    color: #404040;
    font-size: 16px;
    padding: 0 20px;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;
    .tab:hover {
      cursor: pointer;
    }
    .tab {
      width: 110px;
      font-weight: 600;
      span {
        padding: 0 10px;
      }
    }
  }
}
// 下面的滴部
.f-tab {
  // position: absolute;
  // bottom: 0;
  // margin-top: 50px;
  // text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #999999;
  padding: 30px 0;
  .one {
    padding: 18px 0 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1;
      cursor: pointer;
      text-decoration: none;
      color: #444;
    }
    // span{
    // 	padding: 0 3rem;
    // }
  }
  .el-divider--horizontal {
    margin: 18px 0;
  }
  .three {
    padding: 18px 0 0 0;
  }
}
.el-dropdown-link:hover {
  cursor: pointer;
}
</style>
