var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-container',{staticStyle:{"height":"100%","overflow":"hidden"}},[_c('el-aside',{attrs:{"width":"300px"}},[_c('div',{staticClass:"h-left font18"},[_c('img',{attrs:{"src":require("../assets/logo.png"),"alt":""}}),(!_vm.isCollapse)?_c('span',[_vm._v("企业服务版")]):_vm._e()]),_c('div',{staticStyle:{"overflow":"hidden"}},[(_vm.menulist.length)?_c('el-menu',{staticStyle:{"width":"300px"},attrs:{"router":"","background-color":"#2A6378","text-color":"#A6A6A6","active-text-color":"#FFFFFF","unique-opened":"","default-active":_vm.$route.path},on:{"open":_vm.handleOpen}},_vm._l((_vm.menulist),function(item){return _c('el-submenu',{key:item.id,class:item.path,attrs:{"index":item.id + ''}},[_c('template',{slot:"title"},[_c('i',{class:item.icon}),_c('span',[_vm._v(_vm._s(item.authName))])]),_vm._l((item.children),function(subItem){return _c('el-menu-item',{key:subItem.id,attrs:{"index":'/' + subItem.path}},[_c('template',{slot:"title"},[_c('span',{staticStyle:{"margin-left":"58px"}},[_vm._v(_vm._s(subItem.authName))])])],2)})],2)}),1):_vm._e()],1)]),_c('el-container',[_c('el-header',{staticStyle:{"height":"70px","padding":"0"}},[_c('div',{staticClass:"h-right"},[_c('div',{staticClass:"btn"},[_c('div',{staticClass:"navigation font20"},[_vm._v(_vm._s(_vm.$route.name))])]),_c('div',{staticClass:"user_info"},[(
              (_vm.userInfo.isAdmin == 1 && _vm.userInfo.status == 1) ||
              _vm.userInfo.isAdmin != 1
            )?_c('div',{staticClass:"tab",on:{"click":function($event){return _vm.$router.push('message')}}},[_c('el-badge',{staticClass:"item",attrs:{"value":_vm.talk ? _vm.talk : '',"type":"danger"}},[_c('span',[_vm._v("系统消息")])])],1):_vm._e(),(
              (_vm.userInfo.isAdmin == 1 && _vm.userInfo.status == 1) ||
              _vm.userInfo.isAdmin != 1
            )?_c('div',{staticClass:"tab",on:{"click":function($event){return _vm.goChat()}}},[(_vm.newMsgNum > 0)?_c('el-badge',{staticClass:"item",attrs:{"value":_vm.newMsgNum}},[_vm._v(" "+_vm._s("消息")+" ")]):_c('span',[_vm._v(_vm._s("消息"))])],1):_vm._e(),(
              (_vm.userInfo.isAdmin == 1 && _vm.userInfo.status == 1) ||
              _vm.userInfo.isAdmin != 1
            )?_c('div',{staticClass:"tab",on:{"click":function($event){return _vm.$router.push('interviewP')}}},[_c('el-badge',{staticClass:"item",attrs:{"value":_vm.userMessage.interview ? _vm.userMessage.interview : '',"type":"danger"}},[_c('span',[_vm._v("面试")])])],1):_vm._e(),(
              (_vm.userInfo.isAdmin == 1 && _vm.userInfo.status == 1) ||
              _vm.userInfo.isAdmin != 1
            )?_c('div',{staticClass:"tab",on:{"click":function($event){return _vm.$router.push('toudiP')}}},[_c('el-badge',{staticClass:"item",attrs:{"value":_vm.userMessage.postCv ? _vm.userMessage.postCv : '',"type":"danger"}},[_c('span',[_vm._v("投递")])])],1):_vm._e(),(
              (_vm.userInfo.isAdmin == 1 && _vm.userInfo.status == 1) ||
              _vm.userInfo.isAdmin != 1
            )?_c('div',{staticClass:"tab",on:{"click":function($event){return _vm.$router.push('help')}}},[_vm._v(" 帮助反馈 ")]):_vm._e(),_c('el-avatar',[_c('img',{staticStyle:{"object-fit":"cover"},attrs:{"src":_vm.userInfo.avatar
                ? _vm.baseUrl + '/company/file/view/' + _vm.userInfo.avatar
                : 'https://freemen.work/user/file/view/files/20220311/ic_launcher_gray_1646968576594.png'}})]),_c('el-dropdown',{staticStyle:{"margin-left":"10px"},on:{"command":_vm.handleCommand}},[_c('span',{staticClass:"el-dropdown-link"},[_vm._v(" "+_vm._s(_vm.userInfo.displayName)+" "),_c('i',{staticClass:"el-icon-arrow-down el-icon--right"})]),_c('el-dropdown-menu',{attrs:{"slot":"dropdown"},slot:"dropdown"},[_c('el-dropdown-item',{attrs:{"command":"geren"}},[_vm._v("个人资料")]),(_vm.userInfo.isAdmin)?_c('el-dropdown-item',{attrs:{"command":"guanlian"}},[_vm._v("账号关联")]):_vm._e(),_c('el-dropdown-item',{attrs:{"command":"logout"}},[_vm._v("退出登录")])],1)],1)],1)])]),_c('el-main',{staticStyle:{"padding":"20px 126px 20px 126px"}},[_c('keep-alive',[(_vm.$route.meta.keepAlive)?_c('router-view'):_vm._e()],1),(!_vm.$route.meta.keepAlive)?_c('router-view'):_vm._e(),_c('div',{staticClass:"f-tab font14"},[_c('div',{staticClass:"one"},[_c('a',{attrs:{"href":"https://freemen.work/PrivacyPolicy.html","target":"__blank"}},[_vm._v("隐私政策 ")]),_c('a',{attrs:{"href":"/#/help"}},[_vm._v("投诉反馈 ")]),_c('a',{attrs:{"href":"/#/commonwt"}},[_vm._v("常见问题 ")]),_c('a',{attrs:{"href":"/#/aboutus?id=610"}},[_vm._v("联系我们 ")])]),_c('el-divider'),_c('div',{staticClass:"two"},[_vm._v(" © Freemen.work 版权所有 ICP证：浙ICP备20000153号-2 ")]),_c('div',{staticClass:"three"},[_vm._v("浙ICP备20000153号-2")])],1),_c('resumedetails'),_c('reportPopup')],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }